import { Router, Routes , Route} from 'react-router-dom'
import Navbar from '../Navbar'
import React from 'react'
import '../../App.css'
import HeroSection from '../HeroSection'
import Cards from '../Cards'
import Footer from '../Footer'
import Carosel from '../Carosel'
import SimplePaper from '../SimplePaper'


function Home(){
    return (
        <>
        <HeroSection />
        <Cards/>
        <Carosel/>
        <Footer/>
        </>
    )
}

export default Home 