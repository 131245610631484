import React from 'react';
import { MDBCarousel, MDBCarouselItem, MDBCarouselCaption } from 'mdb-react-ui-kit';
import './Carosel.css'


export default function Carosel() {
  return (
    <MDBCarousel showControls  >
      <MDBCarouselItem itemId={1}>
        <img src='/images/carosel1.png' className='d-block w-100' alt='...' />
        <MDBCarouselCaption>
          <h3>Capturing Moments, Crafting Stories</h3>
          <p>Immortalizing your cherished memories through stunning visuals that tell your story.</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>
      <MDBCarouselItem itemId={2}>
        <img src='/images/carosel2.png' className='d-block w-100' alt='...' />

        <MDBCarouselCaption>
          <h3>Your Vision, Our Expertise</h3>
          <p>Transforming your ideas into reality with creativity and precision.</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>
      <MDBCarouselItem itemId={3}>
        <img src='/images/visual.png' className='d-block w-100' alt='...' />
        <MDBCarouselCaption>
          <h3>Turning Ideas into Impactful Visuals</h3>
          <p>Creating powerful imagery that leaves a lasting impression.</p>
        </MDBCarouselCaption>
      </MDBCarouselItem>
    </MDBCarousel>
  );
}