import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Ignite Your Creative Journey!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/identity.png'
              text='Crafting unique brand identities that resonate and inspire.'
              label='Identity'
             /*  path='/services' */
            />
            <CardItem
              src='images/strategy.jpg'
              text='Developing innovative strategies that drive brand success'
              label='Strategy'
             /*  path='/services' */
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/marketing2.png'
              text='Transforming ideas into impactful marketing campaigns that captivate audiences'
              label='Marketting'
             /*  path='/services' */
            />
           {/*  <CardItem
              src='images/img-4.jpg'
              text='Experience Football on Top of the Himilayan Mountains'
              label='Adventure'
              path='/products'
            />
            <CardItem
              src='images/img-8.jpg'
              text='Ride through the Sahara Desert on a guided camel tour'
              label='Adrenaline'
              path='/sign-up'
            /> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;