import React from 'react';
import '../../App.css';

 function Products() {
  return (
    <>
    <h1 >PRODUCTS</h1>

    </>



  )
}

export default Products;